import Apex from './icons/Apex.png';
import balance from './icons/balance.png';
import Csgo from './icons/Csgo.png';
import fortnite from './icons/fortnite.png';
import League from './icons/League.png';
import Rainbow from './icons/Rainbow.png';
import star from './icons/star.png';
import trophy from './icons/trophy.png';


import apexWalp from './walpapers/apex.jpg';
import csgoWalp from './walpapers/csgo.jpg';
import jhin from './walpapers/jhin.png';
import lol from './walpapers/lol.jpg';
import minecraft from './walpapers/minecraft.jpg';
import pubg from './walpapers/pubg.jpg';
import raven from './walpapers/raven.jpg';
import streetfighter from './walpapers/streetfighter.png';
import streetfighter2 from './walpapers/streetfighter2.jpg';
import valo from './walpapers/valo.jpg';
import warzone from './walpapers/warzone.jpg';
import yasuo from './walpapers/yasuo.jpg';


const filesObj = {
	Apex,
	balance,
	Csgo,
	fortnite,
	League,
	lol,
	Rainbow,
	star,
	trophy,
	apexWalp,
	csgoWalp,
	jhin,
	minecraft,
	pubg,
	raven,
	streetfighter,
	streetfighter2,
	valo,
	warzone,
	yasuo,
};
 
export default filesObj;