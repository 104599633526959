import React from 'react';
import './Lives.scss';

const Lives = () => {
	return (
		<section>
			This feature will be added soon!
		</section>
	)
}

export default Lives;