import React from 'react';
import './Tournements.scss';

const Tournements = () => {
	return (
		<section>
			This feature will be added soon!	
		</section>
	)
}

export default Tournements;